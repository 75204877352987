import React from "react"
import Layout from "../components/layout"
import { BsChevronDown } from "react-icons/bs"
import green_city from "../../public/static/images/grean-city-1.jpg"
import about_us_green_city from "../../public/static/images/about_us_green_city.png"
import { BsArrowRight } from "react-icons/bs"
import client_hand_shake from "../../public/static/images/clint-hand-shake.jpg"
import director_photo from "../../public/static/images/director-photo.png"

import "./styles/about-us.css"
import { Link } from "gatsby"
import { Container, Row, Col, Image } from "react-bootstrap"

function About() {
  return (
    <Layout id="about-us">
      {/* <section
        id="adoutus-sec-1"
        style={{ backgroundImage: `url(${green_city})` }}
      >
        <div className="about-sec1-content text-center">
          <p>Welcome to</p>
          <h4 className="animate-charcter">Inventa Energy</h4>
          <Link to="/about-us#about-us-sec" className="down-link">
            <BsChevronDown id="down-arrow" />
          </Link>
        </div>
      </section> */}

      <section id="about-us-sec">
        <Container className="about-us-container" fluid="md">
          <Row className="home-about-row">
            <Col md={7} id="col2">
              <div>
                <h2 id="home-headings">About Us</h2>
                <hr id="home-hr" />
              </div>

              <p>
                Having a 15 years of hard core marketing experience for selling
                industrial and commercial cleaning machines as well as waste
                handling equipment. we name of “Inventa Energy Pvt.
                Ltd.” always strives for providing the best solution for the
                need of the clients and are always ready to take the new
                challenges. now company is marketing all its product under its
                popular brand “Inventa Energy Pvt. Ltd.”. Company owns
                the state of the art infrastructure which helps the company for
                supplying the quality range of products.
              </p>

              <p>
                The entire production techniques are done as per the recommended
                industrial standards; thus making us relevant to the industry.
                We provide the highest quality possible with the help of our
                highly skilled and dedicated employees, state-of-the-art and
                patented products as well as the manufacturing process. Our
                mission is to expand as a business and continually improve our
                range of products byutilizing the new improved quality
                practices, continuous process improvement strategies and by
                working closely with our customers to understand their needs and
                provide them with high quality products and services. With
                quality assurance and stringent evaluations, we aim at providing
                our clients with accurate data, quality products and consistency
                in services.
              </p>
            </Col>
            <Col md={5}>
              <Image src={about_us_green_city} fluid />
            </Col>
          </Row>
        </Container>
      </section>

      <section id="about-us-client-satis" style={{backgroundImage: `url(${client_hand_shake})`}}>
        <Container fluid="md">
          <Row className="client-satis-row">
            <Col md={5}>
              {/* <Image src={happy_client} fluid /> */}
            </Col>
            <Col md={1}></Col>
            <Col md={6}>
              <div>
                <h2 id="home-headings">Client Satisfaction</h2>
                <hr id="home-hr" />
              </div>

              <p>
                We have been able to achieve optimum client satisfaction by
                offering qualitative range of product. In order to attain
                complete satisfaction of our widespread customer base, we
                provide them the customized products as per their application
                requirements. We implement strict quality control procedures to
                ensure that these products are highly reliable and are designed
                exactly as per the international standards. Also, by following
                transparent, fair and ethical business processes, we have
                managed to retain the trust of our clients in us.
              </p>

              <div className="know-more-btn2">
                <Link className="link" to="/contact-us">
                  SEND US A MESSAGE
                  <BsArrowRight id="icon" size="20" />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="adoutus-md-desk">
        <div className="about-md-desk-content">
          <Container fluid>
            <Row>
              <Col className="col1" md={4}>
                <Image id="md-image" src={director_photo} fluid />
              </Col>
              <Col className="col2" md={8}>
                <p id="md">Managing Director's</p>
                <p id="msg">Message</p>
                <p>
                  The key to success of a smarter world lies in both
                  environmentally and socially upgrading our surroundings. It is
                  inevitable and important to be responsible for today and the
                  generations to come Inventa Energy Pvt Ltd. We
                  believe that sustainable development is the key to success and
                  we have aligned ourselves to the mission. In the changed
                  economic scenario, we are constantly reviewing the business
                  modals to create a robust system that will be resilient enough
                  to say above the economic tides. Our clients include many
                  esteemed government & semi-government departments, provided
                  with the best of our services.
                </p>

                <p>
                  We take pride in our work culture to treat the path of
                  sustainable growth across sector.
                </p>
                <p>- Ashwani Srivastava</p>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </Layout>
  )
}

export default About
